import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import styled from "styled-components";
import {
    Row,
    Column,
    PageTitle,
    Button,
    useAddToast,
} from "@cortexglobal/rla-components";
import { CubeLoader } from "@cortexglobal/lens-components";
import { faCaretLeft } from "@fortawesome/free-solid-svg-icons/faCaretLeft";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CompanyTypeChangeRequestForm from "./CompanyTypeChangeRequestForm";

const ContactContainer = styled.div`
    padding: 3rem 0;
    max-width: 800px;
    margin: 0 auto;
`;

const EditCompanyType = ({ match }) => {
    const [loading, setLoading] = useState(false);
    const [companyData, setCompanyData] = useState();
    const history = useHistory();
    const addToast = useAddToast();

    useEffect(() => {
        getCustomer();
    }, []);

    const getCustomer = () => {
        setLoading(true);

        axios
            .get(`/api/v1/customers/${match.params.id}`)
            .then(({ data }) => {
                setCompanyData(data.data);
                setLoading(false);
            })
            .catch((e) => {
                addToast({
                    type: "alert",
                    content: "There was an error getting compaany.",
                    showFor: 5000,
                });
                setLoading(false);
            });
    };

    const handleSubmit = (value) => {
        setLoading(true);
        axios
            .post(`/api/v1/customers/${match.params.id}/type-change-requests`, {
                trade_team_customer_type: value.trade_team_customer_type,
            })
            .then(({ data }) => {
                history.push(`/companies/${data.data.customer_id}`);
                setLoading(false);
            })
            .catch((e) => {
                setLoading(false);
                addToast({
                    type: "alert",
                    content: "There was an error updating your company.",
                    showFor: 5000,
                });
            });
    };

    return (
        <>
            <PageTitle
                title={
                    companyData
                        ? `Request a Customer Type Change: ${companyData.trade_team_customer}`
                        : `Request Customer Type Change`
                }
                expanded
            >
                <Link to={`/companies/${match.params.id}`}>
                    <Button>
                        <FontAwesomeIcon icon={faCaretLeft} /> Back to Customer
                    </Button>
                </Link>
            </PageTitle>

            {loading && <CubeLoader />}

            {!loading && companyData && (
                <ContactContainer>
                    <Row>
                        <Column>
                            <CompanyTypeChangeRequestForm
                                handleSubmit={handleSubmit}
                                loading={loading}
                                values={companyData}
                            />
                        </Column>
                    </Row>
                </ContactContainer>
            )}
        </>
    );
};

export default EditCompanyType;
