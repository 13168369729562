import React from "react";
import styled, { css } from "styled-components";
import {
    Row, Column
} from "@cortexglobal/rla-components";

const Wrapper = styled.section`
    padding-top: 1.5rem;
    margin-bottom: 1.5rem;
    background-color: ${({ theme }) => theme?.pageTitle?.background};
`;

const Title = styled.h1`
    margin: 0 auto 0 0;
    font-size: ${({ theme }) => theme?.pageTitle?.fontSize};
    font-family: ${({ theme }) => theme?.pageTitle?.fontFamily};
`;

const Flex = styled.div`
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: center;
`;

const PageTitle = ({ title, children, ...rest }) => {
    return (
        <Wrapper {...rest}>
            <Row style={{marginBottom: 0}} {...rest}>
                <Column>
                    <Flex>
                        <Title>{title}</Title>
                        {children}
                    </Flex>
                </Column>
            </Row>
        </Wrapper>
    );
};

export default PageTitle;
