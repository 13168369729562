import styled from "styled-components";

export const Title = styled.h3`
    font-size: 1.5em;
    font-weight: bold;
    display: inline-block;
    padding-bottom: 10px;
`;

export const Container = styled.div`
    border: 1px solid #b3b3b3;
    padding: 20px;
    border-radius: 5px;
    margin-bottom: 10px;
`;
