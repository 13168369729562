import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import styled from "styled-components";
import {
    Row,
    Column,
    PageTitle,
    Panel,
    Button,
    TableList,
    InputField,
    Pagination,
    SelectField,
    useAddToast,
} from "@cortexglobal/rla-components";

import {
    StyledTable,
    StyledTableHeader,
    StyledTableRow,
    CubeLoader,
    currentPage,
} from "@cortexglobal/lens-components";

import queryString from "querystring";
import { debounce } from "lodash";
import moment from "moment/moment";

const PaginationContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1em;
`;

const PerPageContainer = styled.div`
    display: flex;
    align-items: center;
    margin-right: auto;
`;

const TotalContainer = styled.div`
    display: flex;
    align-items: center;
    margin-left: auto;
`;

const SearchSection = styled.div`
    display: flex;
    justify-content: flex-end;
    column-gap: 1rem;
`;

const RewardOrdersList = () => {
    const history = useHistory();
    const addToast = useAddToast();

    const { orderBy, orderDir, search, searchBy, perPage, page } =
        queryString.parse(useLocation().search.substring(1));

    const [searchValue, setSearchValue] = useState(search);
    const [searchByValue, setSearchByValue] = useState(searchBy);
    const [perPageValue, setPerPageValue] = useState(perPage || 20);
    const [selectedPageValue, setSelectedPageValue] = useState(
        page === undefined ? 1 : page
    );
    const [orderByValue, setOrderByValue] = useState(orderBy || "created_at");
    const [orderDirectionValue, setOrderDirectionValue] = useState(
        orderDir || "desc"
    );
    const [globalSearchValue, setGlobalSearchValue] = useState(search);

    const [rewardOrders, setRewardOrders] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalRewardOrders, setTotalRewardOrders] = useState();
    const [totalPages, setTotalPages] = useState(0);

    const getRewardOrders = (newData) => {
        setLoading(true);

        axios
            .get(`/api/v1/reward-orders`, {
                params: {
                    page: selectedPageValue,
                    per_page: perPageValue ? perPageValue : 20,
                    order_by: orderByValue,
                    order_dir: orderDirectionValue,
                    search_by: searchByValue,
                    search_val: searchValue,
                    global_search_val: globalSearchValue,
                    ...newData,
                },
            })
            .then(({ data }) => {
                setLoading(false);
                setRewardOrders(data.data);
                setTotalRewardOrders(data.meta.total);
                setTotalPages(data.meta.last_page);
            })
            .catch((e) => {
                addToast({
                    type: "alert",
                    content: "There was an error getting reward orders.",
                    showFor: 5000,
                });
                setLoading(false);
            });
    };

    useEffect(() => {
        getRewardOrders();
    }, []);

    const setParams = (newData) => {
        const data = {
            order_by: orderByValue,
            order_dir: orderDirectionValue,
            search_val: searchValue,
            search_by: searchByValue,
            per_page: perPageValue,
            page: selectedPageValue,
            global_search_val: globalSearchValue,
            ...newData,
        };

        history.push({
            search: `?page=${data.page}&perPage=${data.per_page}${
                data.order_by ? `&orderBy=${data.order_by}` : ""
            }${data.order_dir ? `&orderDir=${data.order_dir}` : ""}${
                data.search_val ? `&search=${data.search_val}` : ""
            }${data.search_val ? `&searchBy=${data.search_by}` : ""}${
                data.global_search_val
                    ? `&globalSearch=${data.global_search_val}`
                    : ""
            }`,
        });
    };

    const debounceSearch = useCallback(
        debounce((callback) => {
            callback();
        }, 1000),
        []
    );

    const handleSearch = (value, searchByValue) => {
        setSearchValue(value);
        setSearchByValue(searchByValue);
        setGlobalSearchValue("");

        debounceSearch(() => {
            setParams({
                search_by: searchByValue,
                search_val: value,
                page: 1,
            });
            getRewardOrders({
                search_by: searchByValue,
                search_val: value,
                page: 1,
            });
        });
    };

    const handleGlobalSearch = ({ value }) => {
        setGlobalSearchValue(value);
        setSearchValue("");
        setSearchByValue("");

        debounceSearch(() => {
            setParams({
                global_search_val: value,
                page: 1,
            });
            getRewardOrders({
                global_search_val: value,
                page: 1,
            });
        });
    };

    const handleSortChange = (value, sortByValue) => {
        setOrderDirectionValue(value);
        setOrderByValue(sortByValue);

        setParams({
            order_by: sortByValue,
            order_dir: value,
            page: 1,
        });
        getRewardOrders({
            order_by: sortByValue,
            order_dir: value,
            page: 1,
        });
    };

    const handlePerPage = ({ value }) => {
        if (value) {
            setPerPageValue(value);

            setParams({
                per_page: value,
                page: 1,
            });
            getRewardOrders({
                per_page: value,
                page: 1,
            });
        }
    };

    const handlePageChange = ({ selected }) => {
        setSelectedPageValue(selected + 1);

        setParams({
            page: selected + 1,
        });
        getRewardOrders({
            page: selected + 1,
        });
    };

    return (
        <>
            <PageTitle title="Reward Orders" expanded />
            <Row collapse expanded>
                <Column collapse>
                    <Row expanded>
                        <Column>
                            <SearchSection>
                                <InputField
                                    name="search"
                                    placeholder="Search"
                                    style={{
                                        maxWidth: "400px",
                                        margin: "0",
                                    }}
                                    value={globalSearchValue}
                                    onChange={handleGlobalSearch}
                                />
                                <Button>Search</Button>
                            </SearchSection>
                        </Column>
                    </Row>
                    <Panel style={{ paddingTop: "0" }}>
                        <StyledTable className="dashboard-table">
                            <thead style={{ position: "sticky", top: "0" }}>
                                <tr>
                                    <StyledTableHeader
                                        isSortable={true}
                                        sortDirection={
                                            orderByValue === "id"
                                                ? orderDirectionValue
                                                : undefined
                                        }
                                    >
                                        ID
                                    </StyledTableHeader>
                                    <StyledTableHeader
                                        isSortable={true}
                                        sortDirection={
                                            orderByValue === "perks_type"
                                                ? orderDirectionValue
                                                : undefined
                                        }
                                    >
                                        Perks Type
                                    </StyledTableHeader>
                                    <StyledTableHeader
                                        isSortable={true}
                                        sortDirection={
                                            orderByValue === "reference"
                                                ? orderDirectionValue
                                                : undefined
                                        }
                                        searchValue={
                                            searchByValue === "reference"
                                                ? searchValue
                                                : ""
                                        }
                                        onSortChange={handleSortChange}
                                        onSearchChange={handleSearch}
                                        hasSearch={true}
                                        name="reference"
                                    >
                                        Reference
                                    </StyledTableHeader>
                                    <StyledTableHeader
                                        isSortable={true}
                                        sortDirection={
                                            orderByValue === "trade_team_id"
                                                ? orderDirectionValue
                                                : undefined
                                        }
                                        searchValue={
                                            searchByValue === "trade_team_id"
                                                ? searchValue
                                                : ""
                                        }
                                        onSortChange={handleSortChange}
                                        onSearchChange={handleSearch}
                                        hasSearch={true}
                                        name="trade_team_id"
                                    >
                                        Trade Team ID
                                    </StyledTableHeader>
                                    <StyledTableHeader
                                        isSortable={true}
                                        sortDirection={
                                            orderByValue === "trade_team_name"
                                                ? orderDirectionValue
                                                : undefined
                                        }
                                        searchValue={
                                            searchByValue === "trade_team_name"
                                                ? searchValue
                                                : ""
                                        }
                                        onSortChange={handleSortChange}
                                        onSearchChange={handleSearch}
                                        hasSearch={true}
                                        name="trade_team_name"
                                    >
                                        Customer Name
                                    </StyledTableHeader>
                                    <StyledTableHeader
                                        isSortable={true}
                                        sortDirection={
                                            orderByValue === "allocated_points"
                                                ? orderDirectionValue
                                                : undefined
                                        }
                                        searchValue={
                                            searchByValue === "allocated_points"
                                                ? searchValue
                                                : ""
                                        }
                                        onSortChange={handleSortChange}
                                        onSearchChange={handleSearch}
                                        hasSearch={true}
                                        name="allocated_points"
                                    >
                                        Points Allocated
                                    </StyledTableHeader>
                                    <StyledTableHeader
                                        isSortable={true}
                                        sortDirection={
                                            orderByValue === "type"
                                                ? orderDirectionValue
                                                : undefined
                                        }
                                        searchValue={
                                            searchByValue === "type"
                                                ? searchValue
                                                : ""
                                        }
                                        onSortChange={handleSortChange}
                                        onSearchChange={handleSearch}
                                        hasSearch={true}
                                        name="type"
                                    >
                                        Reward Type
                                    </StyledTableHeader>
                                    <StyledTableHeader
                                        isSortable={true}
                                        sortDirection={
                                            orderByValue === "status"
                                                ? orderDirectionValue
                                                : undefined
                                        }
                                        searchValue={
                                            searchByValue === "status"
                                                ? searchValue
                                                : ""
                                        }
                                        searchOptions={[
                                            {
                                                label: "Dispatched",
                                                value: "dispatched",
                                            },
                                            {
                                                label: "Ordered",
                                                value: "ordered",
                                            },
                                            {
                                                label: "Pending",
                                                value: "pending",
                                            },
                                            {
                                                label: "Cancelled",
                                                value: "cancelled",
                                            },
                                        ]}
                                        onSortChange={handleSortChange}
                                        onSearchChange={handleSearch}
                                        hasSearch={true}
                                        name="status"
                                    >
                                        Status
                                    </StyledTableHeader>
                                    <StyledTableHeader
                                        isSortable={true}
                                        sortDirection={
                                            orderByValue === "ordered_at"
                                                ? orderDirectionValue
                                                : undefined
                                        }
                                        searchValue={
                                            searchByValue === "ordered_at"
                                                ? searchValue
                                                : ""
                                        }
                                        onSortChange={handleSortChange}
                                        onSearchChange={handleSearch}
                                        hasSearch={true}
                                        name="ordered_at"
                                    >
                                        Ordered At
                                    </StyledTableHeader>
                                    <StyledTableHeader />
                                </tr>
                            </thead>

                            {!loading &&
                                rewardOrders &&
                                rewardOrders.length > 0 && (
                                    <tbody>
                                        {rewardOrders.map((rewardOrder) => (
                                            <StyledTableRow
                                                key={rewardOrder.id}
                                            >
                                                <td>{rewardOrder.id}</td>
                                                <td>
                                                    {rewardOrder.perks_type}
                                                </td>
                                                <td>{rewardOrder.reference}</td>
                                                <td>
                                                    <Link
                                                        to={`/companies/${rewardOrder.customer_id}`}
                                                    >
                                                        {
                                                            rewardOrder.trade_team_id
                                                        }
                                                    </Link>
                                                </td>
                                                <td>
                                                    {rewardOrder.customer_name}
                                                </td>
                                                <td>
                                                    {
                                                        rewardOrder.allocated_points
                                                    }
                                                </td>
                                                <td>
                                                    {rewardOrder.order_type}
                                                </td>
                                                <td>
                                                    {rewardOrder.display_status}
                                                </td>
                                                <td>
                                                    {moment(
                                                        rewardOrder.ordered_at
                                                    ).format(
                                                        "DD/MM/YYYY HH:mm"
                                                    )}
                                                </td>
                                                <td>
                                                    <Link
                                                        to={`/rewards/orders/${rewardOrder.id}`}
                                                    >
                                                        <Button>View</Button>
                                                    </Link>
                                                </td>
                                            </StyledTableRow>
                                        ))}
                                    </tbody>
                                )}
                        </StyledTable>

                        {loading && <CubeLoader />}

                        {!rewardOrders.length && !loading ? (
                            <p style={{ textAlign: "center", margin: "3em 0" }}>
                                No results
                            </p>
                        ) : (
                            <PaginationContainer>
                                <PerPageContainer>
                                    Per Page:
                                    <SelectField
                                        name="perPage"
                                        style={{ margin: "0 0 0 1em" }}
                                        value={perPageValue}
                                        options={[
                                            { value: 10, text: "10" },
                                            { value: 20, text: "20" },
                                            { value: 30, text: "30" },
                                            { value: 40, text: "40" },
                                            { value: 50, text: "50" },
                                        ]}
                                        onChange={handlePerPage}
                                    />
                                </PerPageContainer>
                                <Pagination
                                    currentPage={currentPage(selectedPageValue)}
                                    total={totalRewardOrders}
                                    pageCount={totalPages}
                                    onPageChange={handlePageChange}
                                    previousLabel="&laquo;"
                                    nextLabel="&raquo;"
                                />
                                <TotalContainer>
                                    Total: {totalRewardOrders}
                                </TotalContainer>
                            </PaginationContainer>
                        )}
                    </Panel>
                </Column>
            </Row>
        </>
    );
};

export default RewardOrdersList;
