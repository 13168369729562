import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import axios from "axios";

import {
    Row,
    Column,
    PageTitle,
    Panel,
    Button,
    Tabordion,
    InputField,
    Pagination,
    TableList,
    ButtonDropdown,
    useAddToast,
    SelectField,
} from "@cortexglobal/rla-components";

import { useHistory, useParams, useLocation } from "react-router-dom";
import queryString from "querystring";
import { debounce } from "lodash";
import {
    StyledTable,
    currentPage,
    StyledTableRow,
    StyledTableHeader,
    CubeLoader,
    TrueFalseIcon,
} from "@cortexglobal/lens-components";
const PaginationContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1em;
`;

const PerPageContainer = styled.div`
    display: flex;
    align-items: center;
    margin-right: auto;
`;

const TotalContainer = styled.div`
    display: flex;
    align-items: center;
    margin-left: auto;
`;

const SearchSection = styled.div`
    display: flex;
    justify-content: flex-end;
    column-gap: 1rem;
`;

const ContactsList = (props) => {
    const history = useHistory();
    const addToast = useAddToast();

    const { orderBy, orderDir, search, searchBy, perPage, page } =
        queryString.parse(useLocation().search.substring(1));

    const [searchValue, setSearchValue] = useState(search);
    const [searchByValue, setSearchByValue] = useState(searchBy);
    const [perPageValue, setPerPageValue] = useState(perPage || 20);
    const [selectedPageValue, setSelectedPageValue] = useState(
        page === undefined ? 1 : page
    );
    const [orderByValue, setOrderByValue] = useState(orderBy);
    const [orderDirectionValue, setOrderDirectionValue] = useState(orderDir);
    const [globalSearchValue, setGlobalSearchValue] = useState(search);

    const [contacts, setContacts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalContacts, setTotalContacts] = useState();
    const [totalPages, setTotalPages] = useState(0);

    const getContacts = (newData) => {
        setLoading(true);

        axios
            .get(`/api/v1/contacts`, {
                params: {
                    page: selectedPageValue,
                    per_page: perPageValue ? perPageValue : 20,
                    order_by: orderByValue,
                    order_dir: orderDirectionValue,
                    search_by: searchByValue,
                    search_val: searchValue,
                    global_search_val: globalSearchValue,
                    ...newData,
                },
            })
            .then(({ data }) => {
                setLoading(false);
                setContacts(data.data);
                setTotalContacts(data.meta.total);
                setTotalPages(data.meta.last_page);
            })
            .catch((e) => {
                console.log(e);
                addToast({
                    type: "alert",
                    content: "There was an error getting contacts.",
                    showFor: 5000,
                });
                setLoading(false);
            });
    };

    useEffect(() => {
        getContacts();
    }, []);

    const setParams = (newData) => {
        const data = {
            order_by: orderByValue,
            order_dir: orderDirectionValue,
            search_val: searchValue,
            search_by: searchByValue,
            per_page: perPageValue,
            page: selectedPageValue,
            global_search_val: globalSearchValue,
            ...newData,
        };

        history.push({
            search: `?page=${data.page}&perPage=${data.per_page}${
                data.order_by ? `&orderBy=${data.order_by}` : ""
            }${data.order_dir ? `&orderDir=${data.order_dir}` : ""}${
                data.search_val ? `&search=${data.search_val}` : ""
            }${data.search_val ? `&searchBy=${data.search_by}` : ""}${
                data.global_search_val
                    ? `&globalSearch=${data.global_search_val}`
                    : ""
            }`,
        });
    };

    const debounceSearch = useCallback(
        debounce((callback) => {
            callback();
        }, 1000),
        []
    );

    const handleSearch = (value, searchByValue) => {
        setSearchValue(value);
        setSearchByValue(searchByValue);
        setGlobalSearchValue("");

        debounceSearch(() => {
            setParams({
                search_by: searchByValue,
                search_val: value,
                page: 1,
            });
            getContacts({
                search_by: searchByValue,
                search_val: value,
                page: 1,
            });
        });
    };

    const handleGlobalSearch = ({ value }) => {
        setGlobalSearchValue(value);
        setSearchValue("");
        setSearchByValue("");

        debounceSearch(() => {
            setParams({
                global_search_val: value,
                page: 1,
            });
            getContacts({
                global_search_val: value,
                page: 1,
            });
        });
    };

    const handleSortChange = (value, sortByValue) => {
        setOrderDirectionValue(value);
        setOrderByValue(sortByValue);

        setParams({
            order_by: sortByValue,
            order_dir: value,
            page: 1,
        });
        getContacts({
            order_by: sortByValue,
            order_dir: value,
            page: 1,
        });
    };

    const handlePerPage = ({ value }) => {
        if (value) {
            setPerPageValue(value);

            setParams({
                per_page: value,
                page: 1,
            });
            getContacts({
                per_page: value,
                page: 1,
            });
        }
    };

    const handlePageChange = ({ selected }) => {
        setSelectedPageValue(selected + 1);

        setParams({
            page: selected + 1,
        });
        getContacts({
            page: selected + 1,
        });
    };

    return (
        <>
            <PageTitle title="Contacts" expanded>
                <Link to="/contacts/create">
                    <Button height={38}>Add New Contact</Button>
                </Link>
            </PageTitle>
            <Row collapse expanded>
                <Column collapse>
                    <Row expanded>
                        <Column>
                            <SearchSection>
                                <InputField
                                    name="search"
                                    placeholder="Search"
                                    style={{
                                        maxWidth: "400px",
                                        margin: "0",
                                    }}
                                    value={globalSearchValue}
                                    onChange={handleGlobalSearch}
                                />
                                <Button height={38}>Search</Button>
                            </SearchSection>
                        </Column>
                    </Row>
                    <Panel style={{ paddingTop: "0" }}>
                        <StyledTable className="dashboard-table">
                            <thead style={{ position: "sticky", top: "0" }}>
                                <tr>
                                    <StyledTableHeader>ID</StyledTableHeader>
                                    <StyledTableHeader
                                        isSortable={true}
                                        sortDirection={
                                            orderByValue === "customer_name"
                                                ? orderDirectionValue
                                                : undefined
                                        }
                                        searchValue={
                                            searchByValue === "customer_name"
                                                ? searchValue
                                                : ""
                                        }
                                        onSortChange={handleSortChange}
                                        onSearchChange={handleSearch}
                                        hasSearch={true}
                                        name="customer_name"
                                    >
                                        Customer Name
                                    </StyledTableHeader>
                                    <StyledTableHeader
                                        isSortable={true}
                                        sortDirection={
                                            orderByValue === "first_name"
                                                ? orderDirectionValue
                                                : undefined
                                        }
                                        searchValue={
                                            searchByValue === "first_name"
                                                ? searchValue
                                                : ""
                                        }
                                        onSortChange={handleSortChange}
                                        onSearchChange={handleSearch}
                                        hasSearch={true}
                                        name="first_name"
                                    >
                                        Name
                                    </StyledTableHeader>

                                    <StyledTableHeader
                                        isSortable={true}
                                        sortDirection={
                                            orderByValue === "email"
                                                ? orderDirectionValue
                                                : undefined
                                        }
                                        searchValue={
                                            searchByValue === "email"
                                                ? searchValue
                                                : ""
                                        }
                                        onSortChange={handleSortChange}
                                        onSearchChange={handleSearch}
                                        hasSearch={true}
                                        name="email"
                                    >
                                        Email
                                    </StyledTableHeader>
                                    <StyledTableHeader
                                        isSortable={true}
                                        sortDirection={
                                            orderByValue === "phone"
                                                ? orderDirectionValue
                                                : undefined
                                        }
                                        searchValue={
                                            searchByValue === "phone"
                                                ? searchValue
                                                : ""
                                        }
                                        onSortChange={handleSortChange}
                                        onSearchChange={handleSearch}
                                        hasSearch={true}
                                        name="phone"
                                    >
                                        Phone
                                    </StyledTableHeader>
                                    <StyledTableHeader
                                        isSortable={true}
                                        sortDirection={
                                            orderByValue === "primary_contact"
                                                ? orderDirectionValue
                                                : undefined
                                        }
                                        searchValue={
                                            searchByValue === "primary_contact"
                                                ? searchValue
                                                : ""
                                        }
                                        onSortChange={handleSortChange}
                                        onSearchChange={handleSearch}
                                        hasSearch={true}
                                        name="primary_contact"
                                    >
                                        Primary Contact
                                    </StyledTableHeader>
                                    <StyledTableHeader
                                        isSortable={true}
                                        sortDirection={
                                            orderByValue === "user_status"
                                                ? orderDirectionValue
                                                : undefined
                                        }
                                        searchValue={
                                            searchByValue === "user_status"
                                                ? searchValue
                                                : ""
                                        }
                                        onSortChange={handleSortChange}
                                        onSearchChange={handleSearch}
                                        hasSearch={true}
                                        name="user_status"
                                    >
                                        User Status
                                    </StyledTableHeader>
                                    <StyledTableHeader />
                                </tr>
                            </thead>

                            {!loading && (
                                <tbody>
                                    {contacts.map((contact) => (
                                        <StyledTableRow key={contact.user_uuid}>
                                            <td>{contact.id}</td>
                                            <td>
                                                <Link
                                                    to={`/companies/${contact.customer_id}`}
                                                >
                                                    {contact.customer_name}
                                                </Link>
                                            </td>
                                            <td>
                                                {contact.first_name}{" "}
                                                {contact.last_name}
                                            </td>
                                            <td>{contact.email}</td>
                                            <td>{contact.phone}</td>
                                            <td>
                                                <TrueFalseIcon
                                                    status={
                                                        contact.primary_contact ===
                                                        "Yes"
                                                    }
                                                />
                                            </td>
                                            <td>{contact.user_status}</td>
                                            <td>
                                                <Link
                                                    to={`/contacts/${contact.id}`}
                                                >
                                                    <Button>View</Button>
                                                </Link>
                                            </td>
                                        </StyledTableRow>
                                    ))}
                                </tbody>
                            )}
                        </StyledTable>

                        {loading && <CubeLoader />}

                        <PaginationContainer>
                            <PerPageContainer>
                                Per Page:
                                <SelectField
                                    name="perPage"
                                    style={{ margin: "0 0 0 1em" }}
                                    value={perPageValue}
                                    options={[
                                        { value: 10, text: "10" },
                                        { value: 20, text: "20" },
                                        { value: 30, text: "30" },
                                        { value: 40, text: "40" },
                                        { value: 50, text: "50" },
                                    ]}
                                    onChange={handlePerPage}
                                />
                            </PerPageContainer>
                            <Pagination
                                currentPage={currentPage(selectedPageValue)}
                                total={totalContacts}
                                pageCount={totalPages}
                                onPageChange={handlePageChange}
                                previousLabel="&laquo;"
                                nextLabel="&raquo;"
                            />
                            <TotalContainer>
                                Total: {totalContacts}
                            </TotalContainer>
                        </PaginationContainer>
                    </Panel>
                </Column>
            </Row>
        </>
    );
};

export default ContactsList;
