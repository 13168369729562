import React from "react";

const Pill = ({type, ...props}) => {
    const { children } = props;

    const getColor = (type) => {
        if (type === "success") {
            return "green";
        } else if(type === "warning"){
            return "orange";
        } else if(type === "disabled"){
            return "darkgrey";
        } else {
            return "red";
        }
    };

    return (
        <span
            {...props}
            style={{
                display: "inline-block",
                padding: "0.3em 0.7em",
                borderRadius: "1em",
                fontSize: "0.8em",
                fontWeight: "bold",
                textTransform: "uppercase",
                color: "white",
                backgroundColor: getColor(type),
                ...props.style,
            }}
        >
            {children}
        </span>
    );
};

export default Pill;
