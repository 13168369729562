import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import styled from "styled-components";
import {
    Row,
    Column,
    PageTitle,
    Panel,
    Button,
    FormikInput,
    FormikSelect,
    FormikDatePicker,
    FormikCheckbox,
    FormikTextarea,
    SubmitButton,
    useAddToast,
} from "@cortexglobal/rla-components";
import { faCaretLeft } from "@fortawesome/free-solid-svg-icons/faCaretLeft";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { CubeLoader } from "@cortexglobal/lens-components";
import CompanyForm from "./CompanyForm";

const ContactContainer = styled.div`
    padding: 3rem 0;
    max-width: 800px;
    margin: 0 auto;
`;

const CreateCompany = ({ match }) => {
    const [loading, setLoading] = useState(false);
    const [companyData, setCompanyData] = useState({});
    const [formErrors, setFormErrors] = useState({});
    const history = useHistory();
    const addToast = useAddToast();

    const handleSubmit = (values) => {
        setLoading(true);

        const newCompanyData = {
            company_number: values.company_number,
            trade_team_id: values.trade_team_id,
            trade_team_customer: values.trade_team_customer,
            alternative_customer_name: values.alternative_customer_name,
            trade_team_customer_type: values.trade_team_customer_type,
            rrdi_code: values.rrdi_code,
            dealer_code_for_leads: values.dealer_code_for_leads,
            address_1: values.address_1,
            address_2: values.address_2,
            town: values.town,
            county: values.county,
            postcode: values.postcode,
            email: values.email,
            mobile: values.mobile,
            phone: values.phone,
            vat: values.vat,
            brand_specialist: values.brand_specialist,
            mot_centre: values.mot_centre,
            number_of_bays: values.number_of_bays,
            latest_change_origin: "datacentre-frontend",
            number_of_technicians: values.number_of_technicians,
            number_of_trade_team_programmes:
                values.number_of_trade_team_programmes,
            average_vehicles_per_week: values.average_vehicles_per_week,
            average_weekly_spend_brand_parts:
                values.average_weekly_spend_brand_parts,
            average_age_vehicles: values.average_age_vehicles,
            average_mileage_vehicles: values.average_mileage_vehicles,
            source: values.source,
            website: values.website,
            sic2007_code: values.sic2007_code,
            turnover_band: values.turnover_band,
            turnover_band_value: values.turnover_band_value,
        };

        axios
            .post(`/api/v1/customers`, newCompanyData)
            .then(({ data }) => {
                history.push(`/companies`);
            })
            .catch((e) => {
                setCompanyData(newCompanyData);

                if (e.response !== undefined) {
                    const { status, data } = e.response;

                    if (status === 422 && data.errors !== undefined) {
                        setFormErrors(data.errors);
                    }
                }

                addToast({
                    type: "alert",
                    content: "There was an error creating your company.",
                    showFor: 5000,
                });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <>
            <PageTitle title={`Create: Customer`} expanded>
                <Link to={`/companies`}>
                    <Button>
                        <FontAwesomeIcon icon={faCaretLeft} /> Back to Customers
                    </Button>
                </Link>
            </PageTitle>

            {loading && <CubeLoader />}

            {!loading && (
                <ContactContainer>
                    <Row>
                        <Column>
                            <CompanyForm
                                handleSubmit={handleSubmit}
                                formErors={formErrors}
                                loading={loading}
                                values={companyData}
                                mode="create"
                            />
                        </Column>
                    </Row>
                </ContactContainer>
            )}
        </>
    );
};

export default CreateCompany;
