import React, { useEffect, useState } from "react";
import { Pagination, SelectField } from "@cortexglobal/rla-components";
import {
    StyledTableHeader,
    StyledTable,
    StyledTableRow,
    currentPage,
} from "@cortexglobal/lens-components";

import styled from "styled-components";

const PaginationContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1em;
`;

const PerPageContainer = styled.div`
    display: flex;
    align-items: center;
    margin-right: auto;
`;

const TotalContainer = styled.div`
    display: flex;
    align-items: center;
    margin-left: auto;
`;

const CompanyPoints = ({
    perPageValue,
    selectedPageValue,
    handlePageChange,
    handlePerPage,
    pointsData,
    meta,
}) => {
    return (
        <>
            <StyledTable className="points-table">
                <thead>
                    <tr>
                        <StyledTableHeader name="id">ID</StyledTableHeader>
                        <StyledTableHeader name="perks_type">
                            Perks Type
                        </StyledTableHeader>
                        <StyledTableHeader name="perks_type">
                            Transaction Type
                        </StyledTableHeader>
                        <StyledTableHeader name="points">
                            Points
                        </StyledTableHeader>
                        <StyledTableHeader name="scheme_title">
                            Transaction Info
                        </StyledTableHeader>
                        <StyledTableHeader name="awarded_at">
                            Transaction Date
                        </StyledTableHeader>
                    </tr>
                </thead>
                <tbody>
                    {pointsData.map((point) => (
                        <StyledTableRow key={point.id}>
                            <td>{point.id}</td>
                            <td>{point.perks_type}</td>
                            <td>{point.transaction_type}</td>
                            <td>{point.points.toLocaleString()}</td>
                            <td>
                                {point.point?.scheme ? (
                                    <>
                                        <strong>Point ID:</strong>{" "}
                                        {point.point?.id}
                                        <br />
                                        <strong>Scheme:</strong>{" "}
                                        {point.point?.scheme.title}
                                        <br />
                                        <strong>Category:</strong>{" "}
                                        {point.category?.name || "N/A"}
                                    </>
                                ) : point.reward_order ? (
                                    <>
                                        <strong>Reward Order ID:</strong>{" "}
                                        {point.reward_order.id}
                                        <br />
                                        <strong>Redemption Type:</strong>{" "}
                                        {point.reward_order.order_type}
                                    </>
                                ) : point.adjustment ? (
                                    <>
                                        <strong>Adjustment ID:</strong>
                                        {point.adjustment?.id}
                                        <br />
                                        Previous:{" "}
                                        {point.adjustment?.previous?.toLocaleString()}
                                        <br />
                                        Title: {point.adjustment?.title}
                                        <br />
                                        Reason: {point.adjustment?.reason}
                                    </>
                                ) : (
                                    ""
                                )}
                            </td>
                            <td>
                                {new Date(
                                    point.transaction_date
                                ).toLocaleString()}
                            </td>
                        </StyledTableRow>
                    ))}
                </tbody>
            </StyledTable>
            <PaginationContainer>
                <PerPageContainer>
                    Per Page:
                    <SelectField
                        name="perPage"
                        style={{
                            margin: "0 0 0 1em",
                        }}
                        value={perPageValue}
                        options={[
                            {
                                value: 10,
                                text: "10",
                            },
                            {
                                value: 20,
                                text: "20",
                            },
                            {
                                value: 30,
                                text: "30",
                            },
                            {
                                value: 40,
                                text: "40",
                            },
                            {
                                value: 50,
                                text: "50",
                            },
                        ]}
                        onChange={handlePerPage}
                    />
                </PerPageContainer>
                <Pagination
                    currentPage={currentPage(selectedPageValue)}
                    total={meta.total}
                    pageCount={meta.last_page}
                    onPageChange={handlePageChange}
                    previousLabel="&laquo;"
                    nextLabel="&raquo;"
                />
                <TotalContainer>Total: {meta.total}</TotalContainer>
            </PaginationContainer>
        </>
    );
};

export default CompanyPoints;
