import React from "react";
import {
    Pagination as CortexPagination,
} from "@cortexglobal/rla-components";

const Pagination = (props) => {
    return <CortexPagination {...props} />;
};

export default Pagination;
