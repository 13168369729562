import React, { useEffect, useState } from "react";
import axios from "axios";
import { FormikSelect } from "@cortexglobal/rla-components";

const FormikTurnoverBandSelect = (props) => {
    const [options, setOptions] = useState([]);
    const [optionsLoading, setOptionsLoading] = useState(false);

    useEffect(() => {
        getOptions();
    }, []);

    const getOptions = () => {
        setOptionsLoading(true);

        axios
            .get(`/api/v1/lookups/turnover-bands`)
            .then(({ data }) => {
                setOptions(
                    data.data.map((option) => {
                        return {
                            value: option.key,
                            text: option.value,
                        };
                    })
                );
            })
            .catch((e) => {})
            .finally(() => {
                setOptionsLoading(false);
            });
    };

    return (
        <FormikSelect
            {...props}
            disabled={optionsLoading}
            options={optionsLoading ? [] : options}
        />
    );
};

export default FormikTurnoverBandSelect;
