import React, { useEffect, useState } from "react";
import {
    Button,
    Modal,
    Pagination,
    SelectField,
    useAddToast,
} from "@cortexglobal/rla-components";
import { Link } from "react-router-dom";
import {
    StyledTableHeader,
    StyledTable,
    StyledTableRow,
    currentPage,
    TrueFalseIcon,
} from "@cortexglobal/lens-components";
import styled from "styled-components";

const PaginationContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1em;
`;

const PerPageContainer = styled.div`
    display: flex;
    align-items: center;
    margin-right: auto;
`;

const TotalContainer = styled.div`
    display: flex;
    align-items: center;
    margin-left: auto;
`;

const CompanyContacts = ({
    tradeTeamId,
    perPageValue,
    selectedPageValue,
    handlePageChange,
    handlePerPage,
    contacts,
    meta,
}) => {
    return (
        <>
            <div
                style={{
                    textAlign: "right",
                    padding: "1em 2em 0.5em",
                }}
            >
                <Link to={`/contacts/create?id=${tradeTeamId}`}>
                    <Button>Add New Contact</Button>
                </Link>
            </div>
            {!!contacts.length && (
                <StyledTable className="contacts-table">
                    <thead>
                        <tr>
                            <StyledTableHeader name="name">
                                ID
                            </StyledTableHeader>
                            <StyledTableHeader name="name">
                                Name
                            </StyledTableHeader>
                            <StyledTableHeader name="email">
                                Email
                            </StyledTableHeader>
                            <StyledTableHeader name="primary_contact">
                                Is Primary Contact
                            </StyledTableHeader>
                            <StyledTableHeader name="can_nominate_ar">
                                Can Nominate AR
                            </StyledTableHeader>
                            <StyledTableHeader name="business_email">
                                Is Business Email
                            </StyledTableHeader>
                            <StyledTableHeader name="legitimate_interest">
                                Has Legitimate Interest
                            </StyledTableHeader>
                            <StyledTableHeader name="ar_perks_permission">
                                Has AR PERKS Permission
                            </StyledTableHeader>
                            <StyledTableHeader name="channel_email">
                                Email Allowed
                            </StyledTableHeader>
                            <StyledTableHeader name="channel_email">
                                Sms Allowed
                            </StyledTableHeader>
                            <StyledTableHeader name="channel_email">
                                Post Allowed
                            </StyledTableHeader>
                            <StyledTableHeader />
                        </tr>
                    </thead>
                    <tbody>
                        {contacts.map((contact) => (
                            <StyledTableRow key={contact.id}>
                                <td>{contact.id}</td>
                                <td>
                                    {contact.first_name} {contact.last_name}
                                </td>
                                <td>{contact.email}</td>
                                <td>
                                    <TrueFalseIcon
                                        status={
                                            contact.primary_contact === "Yes"
                                        }
                                    />{" "}
                                    {contact.primary_contact}
                                </td>
                                <td>
                                    <TrueFalseIcon
                                        status={
                                            contact.can_nominate_ar === "Yes"
                                        }
                                    />{" "}
                                    {contact.can_nominate_ar}
                                </td>
                                <td>
                                    <TrueFalseIcon
                                        status={
                                            contact.business_email === "Yes"
                                        }
                                    />{" "}
                                    {contact.business_email}
                                </td>
                                <td>
                                    <TrueFalseIcon
                                        status={
                                            contact.legitimate_interest ===
                                            "Yes"
                                        }
                                    />{" "}
                                    {contact.legitimate_interest}
                                </td>
                                <td>
                                    <TrueFalseIcon
                                        status={
                                            contact.ar_perks_permission ===
                                            "Yes"
                                        }
                                    />{" "}
                                    {contact.ar_perks_permission}
                                </td>
                                <td>
                                    <TrueFalseIcon
                                        status={contact.channel_email === "Yes"}
                                    />{" "}
                                    {contact.channel_email}
                                </td>
                                <td>
                                    <TrueFalseIcon
                                        status={contact.channel_sms === "Yes"}
                                    />{" "}
                                    {contact.channel_sms}
                                </td>
                                <td>
                                    <TrueFalseIcon
                                        status={contact.channel_post === "Yes"}
                                    />{" "}
                                    {contact.channel_post}
                                </td>
                                <td>
                                    <Link to={`/contacts/${contact.id}`}>
                                        <Button>View Contact</Button>
                                    </Link>
                                </td>
                            </StyledTableRow>
                        ))}
                    </tbody>
                </StyledTable>
            )}
            <PaginationContainer>
                <PerPageContainer>
                    Per Page:
                    <SelectField
                        name="perPage"
                        style={{ margin: "0 0 0 1em" }}
                        value={perPageValue}
                        options={[
                            { value: 10, text: "10" },
                            { value: 20, text: "20" },
                            { value: 30, text: "30" },
                            { value: 40, text: "40" },
                            { value: 50, text: "50" },
                        ]}
                        onChange={handlePerPage}
                    />
                </PerPageContainer>
                <Pagination
                    currentPage={currentPage(selectedPageValue)}
                    total={meta.total}
                    pageCount={meta.last_page}
                    onPageChange={handlePageChange}
                    previousLabel="&laquo;"
                    nextLabel="&raquo;"
                />
                <TotalContainer>Total: {meta.total}</TotalContainer>
            </PaginationContainer>
        </>
    );
};

export default CompanyContacts;
