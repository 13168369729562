import React, { useState, useRef, useEffect } from "react";
import {
    Column,
    Panel,
    Row,
    SubmitButton,
    useAddToast,
    FormikDatePicker,
} from "@cortexglobal/rla-components";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import axios from "axios";
import FormikCompanyTypePicker from "./FormikCompanyTypePicker";

const Schema = Yup.object().shape({
    trade_team_customer_type: Yup.string(),
    type: Yup.string(),
});

const CompanyTypeChangeRequestForm = ({ handleSubmit, loading, values }) => {
    const addToast = useAddToast();
    const [typeCheckLoading, setTypeCheckLoading] = useState(false);

    return (
        <Panel style={{ padding: "2em" }}>
            <Formik
                initialValues={{
                    trade_team_customer_type:
                        values && values.trade_team_customer_type
                            ? values.trade_team_customer_type
                            : "",
                    type: values && values.type ? values.type : "",
                    perks_type_name:
                        values && values.perks_type_name
                            ? values.perks_type_name
                            : "",
                    customer_type:
                        values && values.customer_type
                            ? values.customer_type
                            : "",
                }}
                onSubmit={handleSubmit}
                validationSchema={Schema}
            >
                {({
                    isSubmitting,
                    values,
                    setFieldValue,
                    handleBlur,
                    errors,
                }) => {
                    const tomorrow = new Date(Date.now() + 86400000);

                    return (
                        <Form>
                            {/*<Row>*/}
                            {/*    <Column>*/}
                            {/*        <FormikDatePicker*/}
                            {/*            minDate={tomorrow}*/}
                            {/*            value={*/}
                            {/*                values.date_of_type_change ||*/}
                            {/*                tomorrow.toISOString()*/}
                            {/*            }*/}
                            {/*            width="100%"*/}
                            {/*            labelWidth={100}*/}
                            {/*            name="date_of_type_change"*/}
                            {/*            label="Date of Change:"*/}
                            {/*        />*/}
                            {/*    </Column>*/}
                            {/*</Row>*/}
                            <Row>
                                <Column>
                                    <FormikCompanyTypePicker
                                        value={values.trade_team_customer_type}
                                        primaryType={values.type}
                                        perksTypeName={values.perks_type_name}
                                        setFieldValue={setFieldValue}
                                        loading={loading}
                                    />
                                </Column>
                            </Row>
                            <Row>
                                <Column
                                    style={{
                                        textAlign: "right",
                                    }}
                                >
                                    <SubmitButton
                                        submitting={typeCheckLoading || loading}
                                    >
                                        Save
                                    </SubmitButton>
                                </Column>
                            </Row>
                        </Form>
                    );
                }}
            </Formik>
        </Panel>
    );
};

export default CompanyTypeChangeRequestForm;
