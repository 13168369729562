import React from "react";
import { Pill } from "@cortexglobal/lens-components";

const CustomerStatusPill = (props) => {
    let type = "warning";

    if (props.children === "Active") {
        type = "success";
    } else if (props.children === "Deleted") {
        type = "danger";
    } else if (props.children === "Dead") {
        type = "disabled";
    }

    return <Pill type={type} {...props} />;
};

export default CustomerStatusPill;
