import React, { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";
import {
    Row,
    Column,
    InputField,
    SubmitButton,
    useAddToast,
    Button,
    TextareaField,
    Modal,
    Table,
} from "@cortexglobal/rla-components";
import { Title, Container } from "../components/generalLayoutComponents";
import { StyledTable, StyledTableRow } from "@cortexglobal/lens-components";

const CustomerSummary = ({ customerSummary }) => {
    return (
        <StyledTable style={{ width: "100%", margin: "0" }}>
            <tbody>
                <StyledTableRow>
                    <td>
                        <strong>Trade Team ID:</strong>
                    </td>
                    <td>{customerSummary.trade_team_id || "-"}</td>
                </StyledTableRow>
                <StyledTableRow>
                    <td>
                        <strong>Company Number: </strong>
                    </td>
                    <td>{customerSummary.company_number || "-"}</td>
                </StyledTableRow>
                <StyledTableRow>
                    <td>
                        <strong>Customer: </strong>
                    </td>
                    <td>{customerSummary.trade_team_customer || "-"}</td>
                </StyledTableRow>
                <StyledTableRow>
                    <td>
                        <strong>Customer Type: </strong>
                    </td>
                    <td>{customerSummary.trade_team_customer_type || "-"}</td>
                </StyledTableRow>
                <StyledTableRow>
                    <td>
                        <strong>Address 1: </strong>
                    </td>
                    <td>{customerSummary.address_1 || "-"}</td>
                </StyledTableRow>
                <StyledTableRow>
                    <td>
                        <strong>Address 2: </strong>
                    </td>
                    <td>{customerSummary.address_2 || "-"}</td>
                </StyledTableRow>
                <StyledTableRow>
                    <td>
                        <strong>City/Town: </strong>
                    </td>
                    <td>{customerSummary.town || "-"}</td>
                </StyledTableRow>
                <StyledTableRow>
                    <td>
                        <strong>Postcode: </strong>
                    </td>
                    <td>{customerSummary.postcode || "-"}</td>
                </StyledTableRow>
                <StyledTableRow>
                    <td>
                        <strong>Phone: </strong>
                    </td>
                    <td>{customerSummary.phone || "-"}</td>
                </StyledTableRow>
                <StyledTableRow>
                    <td>
                        <strong>Mobile: </strong>
                    </td>
                    <td>{customerSummary.mobile || "-"}</td>
                </StyledTableRow>
                <StyledTableRow>
                    <td>
                        <strong>Email: </strong>
                    </td>
                    <td>{customerSummary.email || "-"}</td>
                </StyledTableRow>
                <StyledTableRow>
                    <td>
                        <strong>Activation Date: </strong>
                    </td>
                    <td>
                        {customerSummary.activated_at
                            ? moment(customerSummary.activated_at).format(
                                  "DD/MM/YYYY"
                              )
                            : "-"}
                    </td>
                </StyledTableRow>
            </tbody>
        </StyledTable>
    );
};

const IndividualManualMatch = ({
    companyDetails,
    onClose,
    handleAcceptCustomerMerge,
    loading,
}) => {
    const [acceptWarning, setAcceptWarning] = useState(false);
    const [fetchingSummary, setFetchingSummary] = useState(false);
    const [showFetchSummaryError, setShowFetchSummaryError] = useState(false);
    const [customerSummary, setCustomerSummary] = useState();

    const [searchTerm, setSearchTerm] = useState();
    const [reasonToBeMerge, setReasonToBeMerge] = useState();

    const addToast = useAddToast();

    const handleFetchCustomerSummary = () => {
        if (searchTerm) {
            setFetchingSummary(true);
            setShowFetchSummaryError(false);

            if (parseInt(searchTerm) === companyDetails.trade_team_id) {
                setCustomerSummary(undefined);
                setShowFetchSummaryError(
                    "Merge unavailable, supplied companies are the same"
                );
                setFetchingSummary(false);
                return;
            }

            axios
                .get(`/api/v1/customers/search?term=${searchTerm}`)
                .then(({ data }) => {
                    setFetchingSummary(false);
                    setCustomerSummary(data.data);
                })
                .catch((error) => {
                    setCustomerSummary(undefined);
                    setShowFetchSummaryError(error.response.data.error);
                    setFetchingSummary(false);
                });
        }
    };

    useEffect(() => {}, []);

    return (
        <Row>
            <Column>
                <Title>Merge Customers</Title>
            </Column>

            <Column medium={12}>
                <Container>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        <InputField
                            value={searchTerm}
                            width="100%"
                            labelWidth={100}
                            name="searchTerm"
                            label="Trade Team ID"
                            onChange={({ value }) => setSearchTerm(value)}
                        />

                        <SubmitButton
                            submitting={fetchingSummary}
                            onClick={() =>
                                handleFetchCustomerSummary(searchTerm)
                            }
                            style={{
                                marginLeft: "1rem",
                            }}
                        >
                            Search
                        </SubmitButton>
                    </div>
                </Container>
            </Column>

            <Column medium={6}>
                <Container>
                    <Title>This Customer</Title>
                    <CustomerSummary customerSummary={companyDetails} />
                </Container>
            </Column>

            {customerSummary && (
                <Column medium={6}>
                    <Container>
                        <Title>Customer To Be Merged (Deleted)</Title>
                        <CustomerSummary customerSummary={customerSummary} />
                    </Container>
                </Column>
            )}

            {customerSummary && (
                <Column medium={12}>
                    <Container>
                        <>
                            <div>
                                <TextareaField
                                    width="100%"
                                    labelWidth={100}
                                    name="reason"
                                    label="Reason for merge"
                                    onChange={({ value }) =>
                                        setReasonToBeMerge(value)
                                    }
                                />
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent:
                                        "space-between" /* Pushes elements to the edges */,
                                    width: "100%" /* Ensures full width */,
                                    marginTop: "20px",
                                }}
                            >
                                <SubmitButton
                                    onClick={() => setCustomerSummary()}
                                    disabled={!customerSummary || !!loading}
                                    style={{
                                        marginRight: "1rem",
                                        backgroundColor: "#cc0000",
                                        borderColor: "#cc0000",
                                        boxShadow: "none",
                                    }}
                                >
                                    Cancel
                                </SubmitButton>

                                <SubmitButton
                                    loading={!!loading}
                                    onClick={() => setAcceptWarning(true)}
                                    disabled={!customerSummary || !!loading}
                                >
                                    Accept
                                </SubmitButton>
                            </div>
                        </>
                    </Container>
                </Column>
            )}
            <Column medium={12}>
                {showFetchSummaryError && (
                    <Container>
                        <p style={{ color: "red", fontWeight: "bold" }}>
                            {showFetchSummaryError}
                        </p>
                    </Container>
                )}
            </Column>

            <Modal
                visible={!!acceptWarning}
                onClose={() => setAcceptWarning(null)}
                maxWidth="40%"
            >
                <div>
                    <Container style={{ marginTop: "2rem" }}>
                        <p style={{ textTransform: "uppercase" }}>
                            deleting distrigo id{" "}
                            {customerSummary?.trade_team_id}{" "}
                        </p>
                        <p style={{ textTransform: "uppercase" }}>
                            customer{" "}
                            <span style={{ fontWeight: "bold" }}>
                                {customerSummary?.trade_team_customer}
                            </span>
                        </p>
                        <p>This action will merge the following information:</p>
                        <ul>
                            <li style={{ fontStyle: "italic" }}>Points</li>
                            <li style={{ fontStyle: "italic" }}>
                                Communications
                            </li>
                            <li style={{ fontStyle: "italic" }}>
                                Matched Links
                            </li>
                            <li style={{ fontStyle: "italic" }}>Sales</li>
                        </ul>
                        <p>The EARLIEST activation date will picked.</p>
                        <p>
                            Note: This action is not instant but you will be
                            able to see the progress on Company View under the
                            Merge Log Tab.
                        </p>
                        <div
                            style={{
                                display: "flex",
                                justifyContent:
                                    "space-between" /* Pushes elements to the edges */,
                                width: "100%" /* Ensures full width */,
                                marginTop: "20px",
                            }}
                        >
                            <SubmitButton
                                onClick={() => {
                                    setAcceptWarning(null);
                                }}
                                disabled={!customerSummary || !!loading}
                                style={{
                                    backgroundColor: "#cc0000",
                                    borderColor: "#cc0000",
                                    boxShadow: "none",
                                }}
                            >
                                Cancel
                            </SubmitButton>
                            <SubmitButton
                                loading={!!loading}
                                onClick={() =>
                                    handleAcceptCustomerMerge({
                                        customer_id_from: customerSummary.id,
                                        reason: reasonToBeMerge,
                                    })
                                }
                                disabled={!customerSummary || !!loading}
                            >
                                Still Accept
                            </SubmitButton>
                        </div>
                    </Container>
                </div>
            </Modal>
        </Row>
    );
};

export default IndividualManualMatch;
