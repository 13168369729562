import React, { useState, useEffect } from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import ViewCompany from "./ViewCompany";
import EditCompany from "./EditCompany";
import EditCompanyType from "./EditCompanyType";
import CreateCompany from "./CreateCompany";
import CompanyList from "./CompanyList";
import axios from "axios";
import { Row, Column, PageTitle, Panel } from "@cortexglobal/rla-components";
import EditContact from "../contacts/EditContact";

const Companies = (props) => {
    let match = useRouteMatch();

    return (
        <>
            <Switch>
                <Route
                    path={`${match.path}/edit/:id/type`}
                    component={EditCompanyType}
                />
                <Route
                    path={`${match.path}/edit/:id`}
                    component={EditCompany}
                />
                <Route
                    path={`${match.path}/create`}
                    component={CreateCompany}
                />
                <Route path={`${match.path}/:id`} component={ViewCompany} />
                <Route path={`${match.path}`} component={CompanyList} />
            </Switch>
        </>
    );
};

export default Companies;
