import React, { useState, useRef, useEffect } from "react";
import {
    Button,
    FormLabel,
    MultiCheckbox,
    useAddToast,
} from "@cortexglobal/rla-components";
import { CubeLoader } from "@cortexglobal/lens-components";
import axios from "axios";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSpring, animated } from "react-spring";

const FormikCompanyTypePicker = ({
    value = "",
    primaryType = "",
    perksTypeName = "",
    setFieldValue = () => {},
    loading = false,
    error = "",
}) => {
    const addToast = useAddToast();
    const [style, animate] = useSpring(() => ({ height: "0px" }), []);
    const expandableContainerRef = useRef(null);

    const [typeList, setTypeList] = useState([]);
    const [showFullTypeList, setShowFullTypeList] = useState(false);

    const [typeCheckLoading, setTypeCheckLoading] = useState(false);

    const [selectedValue, setSelectedValue] = useState(value);
    const [selectedPrimaryType, setSelectedPrimaryType] = useState(primaryType);
    const [selectedPerksTypeName, setSelectedPerksTypeName] =
        useState(perksTypeName);

    useEffect(() => {
        animate({
            height:
                (showFullTypeList
                    ? expandableContainerRef.current.offsetHeight + 2
                    : 300) + "px",
        });
    }, [animate, expandableContainerRef, showFullTypeList]);

    useEffect(() => {
        getCustomerTypes();
    }, []);

    const getCustomerTypes = () => {
        // sometimes the customer type list contains a type which is not in the default list so we need to merge these in
        const existingTypes = csvStringToArray(value || []);

        axios
            .get(`/api/v1/customers/types`)
            .then(({ data }) => {
                let types = data.data;

                existingTypes.forEach((element) => {
                    if (!types.some((type) => type.value === element)) {
                        types.push({
                            label: element,
                            value: element,
                            text: element,
                        });
                    }
                });

                // Sort the array alphabetically after adding the new elements
                types.sort((a, b) => a.value.localeCompare(b.value));

                setTypeList(types);
            })
            .catch((e) => {
                addToast({
                    type: "alert",
                    content: "There was an error getting customer types.",
                    showFor: 5000,
                });
            });
    };

    const checkCustomerTypes = (types) => {
        console.log("types", types);
        setTypeCheckLoading(true);

        let trimmedTypes = types
            .map((type) => type.trim())
            .filter((type) => type.length);

        if (!trimmedTypes.length) {
            setSelectedValue("");
            setSelectedPrimaryType("");
            setSelectedPerksTypeName("");

            setTypeCheckLoading(false);
            return;
        }

        const typeListCsv = trimmedTypes.join(", ");

        setSelectedValue(typeListCsv);
        setFieldValue("trade_team_customer_type", typeListCsv);

        axios
            .post(`/api/v1/customers/types`, {
                types: trimmedTypes,
            })
            .then(({ data }) => {
                const { eligible_for_perks, primary_type } = data;
                setSelectedPrimaryType(primary_type);
                setSelectedPerksTypeName(eligible_for_perks);
            })
            .catch((e) => {
                addToast({
                    type: "alert",
                    content:
                        "There was an error checking the chosen customer type.",
                    showFor: 5000,
                });
            })
            .finally(() => {
                setTypeCheckLoading(false);
            });
    };

    const csvStringToArray = (csvString) => {
        if (!csvString || typeof csvString !== "string") {
            return [];
        }

        return csvString.split(",").map((item) => item.trim());
    };

    return (
        <>
            <FormLabel>Customer Type(s)</FormLabel>

            <div style={{ position: "relative" }}>
                <Button
                    style={{
                        position: "absolute",
                        top: "1rem",
                        right: "1.4rem",
                        padding: "0 0.6rem",
                        zIndex: 1,
                    }}
                    onClick={(event) => {
                        event.preventDefault();
                        setShowFullTypeList(!showFullTypeList);
                    }}
                    height={26}
                >
                    {showFullTypeList ? (
                        <>
                            Collapse <FontAwesomeIcon icon={faMinus} />
                        </>
                    ) : (
                        <>
                            Expand <FontAwesomeIcon icon={faPlus} />
                        </>
                    )}
                </Button>

                <animated.div
                    style={{
                        minHeight: "calc(15px + 2.4rem)",
                        margin: "0 auto 1rem",
                        border: "1px solid #aaafb7",
                        overflow: "auto",
                        width: "100%",
                        ...style,
                    }}
                >
                    <div
                        style={{
                            padding: "1.2rem 1.2rem 1px",
                        }}
                        ref={expandableContainerRef}
                    >
                        {!typeList.length && (
                            <CubeLoader size={20} margin="0.5rem" />
                        )}
                        <MultiCheckbox
                            name="trade_team_customer_type"
                            value={csvStringToArray(selectedValue)}
                            options={typeList}
                            onChange={({ value }) =>
                                checkCustomerTypes(value, setFieldValue)
                            }
                            error={error}
                        />
                    </div>
                </animated.div>
            </div>

            <div
                style={{
                    marginBottom: "15px",
                }}
            >
                {selectedValue &&
                    csvStringToArray(selectedValue).map((selectedType) => (
                        <span
                            style={{
                                margin: "0 5px 5px 5px",
                                padding: "4px 7px",
                                fontSize: "0.8rem",
                                background: "#f0f0f0",
                                borderRadius: "15px",
                                whiteSpace: "nowrap",
                                display: "inline-block",
                            }}
                        >
                            {selectedType}
                        </span>
                    ))}
            </div>

            <div
                style={{
                    marginBottom: "15px",
                }}
            >
                <strong>Primary Type:</strong>{" "}
                {typeCheckLoading ? (
                    <span>...</span>
                ) : selectedPrimaryType ? (
                    selectedPrimaryType
                ) : (
                    "Please select at least one customer type"
                )}
            </div>

            <div
                style={{
                    marginBottom: "15px",
                }}
            >
                <strong>Perks Scheme:</strong>{" "}
                {typeCheckLoading ? (
                    <span>...</span>
                ) : selectedPerksTypeName ? (
                    selectedPerksTypeName
                ) : (
                    "Please select at least one customer type"
                )}
            </div>
        </>
    );
};

export default FormikCompanyTypePicker;
