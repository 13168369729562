import React, { useEffect, useState } from "react";
import axios from "axios";
import { FormikSelect } from "@cortexglobal/rla-components";

const FormikSicCodeSelect = (props) => {
    const [options, setOptions] = useState([]);
    const [optionsLoading, setOptionsLoading] = useState(false);

    useEffect(() => {
        getOptions();
    }, []);

    const getOptions = () => {
        setOptionsLoading(true);

        axios
            .get(`/api/v1/lookups/sic-codes`)
            .then(({ data }) => {
                // surface the relevant options first
                const topOptions = data.data.filter(
                    (option) => option.sub_reference === "relevant"
                );

                const otherOptions = data.data.filter(
                    (option) => option.sub_reference !== "relevant"
                );

                setOptions(
                    [...topOptions, ...otherOptions].map((option) => {
                        return {
                            value: option.key,
                            text: option.value,
                        };
                    })
                );
            })
            .catch((e) => {})
            .finally(() => {
                setOptionsLoading(false);
            });
    };

    return (
        <FormikSelect
            {...props}
            disabled={optionsLoading}
            options={optionsLoading ? [] : options}
        />
    );
};

export default FormikSicCodeSelect;
