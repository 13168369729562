import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
    Button,
    Column,
    FormikInput,
    Panel,
    Row,
    SubmitButton,
    useAddToast,
    FormikSelect,
} from "@cortexglobal/rla-components";
import { CubeLoader } from "@cortexglobal/lens-components";
import FormikCompanyTypePicker from "./FormikCompanyTypePicker";
import FormikSicCodeSelect from "./FormikSicCodeSelect";
import FormikTurnoverBandSelect from "./FormikTurnoverBandSelect";

const Schema = Yup.object().shape({
    company_number: Yup.string(),
    trade_team_customer: Yup.string().required("Required"),
    alternative_customer_name: Yup.string(),
    rrdi_code: Yup.string(),
    dealer_code_for_leads: Yup.string(),
    trade_team_customer_type: Yup.string(),
    address_1: Yup.string(),
    address_2: Yup.string(),
    address_3: Yup.string(),
    town: Yup.string(),
    county: Yup.string(),
    email: Yup.string(),
    phone: Yup.string(),
    website: Yup.string(),
    sic2007_code: Yup.string(),
});

const CompanyForm = ({
    handleSubmit,
    loading,
    values,
    mode,
    formErrors = {},
}) => {
    const addToast = useAddToast();
    const [phoneMatchError, setPhoneMatchError] = useState(false);

    const handlePhoneBlur = async (event, trade_team_id) => {
        const phone = event.target.value;
        const data = { phone };

        if (trade_team_id !== null && trade_team_id !== 0) {
            data.trade_team_id = trade_team_id;
        }

        try {
            const response = await axios.post(
                "/api/v1/customers/phone-number-check",
                data
            );
            const found = response.data.data.found;
            setPhoneMatchError(found);
        } catch (error) {
            setPhoneMatchError(false);
        }
    };

    return (
        <Panel style={{ padding: "2em" }}>
            <Formik
                initialValues={{
                    company_number: values?.company_number || "",
                    trade_team_customer: values?.trade_team_customer || "",
                    alternative_customer_name:
                        values?.alternative_customer_name || "",
                    rrdi_code: values?.rrdi_code || "",
                    dealer_code_for_leads: values?.dealer_code_for_leads || "",
                    trade_team_customer_type:
                        values?.trade_team_customer_type || "",
                    address_1: values?.address_1 || "",
                    address_2: values?.address_2 || "",
                    address_3: values?.address_3 || "",
                    town: values?.town || "",
                    county: values?.county || "",
                    postcode: values?.postcode || "",
                    email: values?.email || "",
                    phone: values?.phone || "",
                    vat: values?.vat || "",
                    brand_specialist: values?.brand_specialist || "",
                    mot_centre: values?.mot_centre || "",
                    number_of_bays: values?.number_of_bays || "",
                    number_of_technicians: values?.number_of_technicians || 0,
                    number_of_trade_team_programmes:
                        values?.number_of_trade_team_programmes || "",
                    average_vehicles_per_week:
                        values?.average_vehicles_per_week || "",
                    average_weekly_spend_brand_parts:
                        values?.average_weekly_spend_brand_parts || "",
                    average_age_vehicles: values?.average_age_vehicles || "",
                    trade_team_id: values?.trade_team_id || "",
                    turnover_band: values?.turnover_band || "",
                    turnover_band_value: values?.turnover_band_value || "",
                    website: values?.website || "",
                    sic2007_code: values?.sic2007_code || "",
                    source: values?.source || "",
                }}
                onSubmit={handleSubmit}
                validationSchema={Schema}
            >
                {({
                    isSubmitting,
                    values,
                    setFieldValue,
                    handleBlur,
                    errors,
                }) => {
                    return (
                        <Form>
                            <Row>
                                <Column>
                                    <FormikInput
                                        type="hidden"
                                        name="trade_team_id"
                                        value={values.trade_team_id || ""}
                                    />
                                    <FormikInput
                                        value={values.company_number}
                                        width="100%"
                                        labelWidth={100}
                                        name="company_number"
                                        label="Company Number"
                                    />
                                    <FormikInput
                                        value={values.trade_team_customer}
                                        width="100%"
                                        labelWidth={100}
                                        name="trade_team_customer"
                                        label="Customer Name"
                                    />
                                    <FormikInput
                                        value={values.alternative_customer_name}
                                        width="100%"
                                        labelWidth={100}
                                        name="alternative_customer_name"
                                        label="Alternative Customer Name"
                                    />
                                    <FormikInput
                                        value={values.rrdi_code}
                                        width="100%"
                                        labelWidth={100}
                                        name="rrdi_code"
                                        label="RRDI Code"
                                        maxLength={10}
                                    />
                                    <FormikInput
                                        value={values.dealer_code_for_leads}
                                        width="100%"
                                        labelWidth={100}
                                        name="dealer_code_for_leads"
                                        label="Dealer Code For Leads"
                                        maxLength={10}
                                    />
                                    <FormikInput
                                        value={values.website}
                                        width="100%"
                                        labelWidth={100}
                                        name="website"
                                        label="Website"
                                    />
                                </Column>
                            </Row>
                            {mode === "create" && (
                                <Row style={{ marginTop: "30px" }}>
                                    <Column>
                                        <FormikCompanyTypePicker
                                            value={
                                                values.trade_team_customer_type
                                            }
                                            primaryType={values.type}
                                            perksTypeName={
                                                values.perks_type_name
                                            }
                                            setFieldValue={setFieldValue}
                                            loading={loading}
                                            error={
                                                formErrors?.trade_team_customer_type ||
                                                ""
                                            }
                                        />
                                    </Column>
                                </Row>
                            )}
                            <Row style={{ marginTop: "30px" }}>
                                <Column medium={6}>
                                    <FormikInput
                                        value={values.address_1}
                                        width="100%"
                                        labelWidth={100}
                                        name="address_1"
                                        label="Address 1"
                                    />
                                    <FormikInput
                                        value={values.address_2}
                                        width="100%"
                                        labelWidth={100}
                                        name="address_2"
                                        label="Address 2"
                                    />
                                    <FormikInput
                                        value={values.address_3}
                                        width="100%"
                                        labelWidth={100}
                                        name="address_3"
                                        label="Address 3"
                                    />
                                </Column>
                                <Column medium={6}>
                                    <FormikInput
                                        value={values.town}
                                        width="100%"
                                        labelWidth={100}
                                        name="town"
                                        label="Town"
                                    />
                                    <FormikInput
                                        value={values.county}
                                        width="100%"
                                        labelWidth={100}
                                        name="county"
                                        label="County"
                                    />
                                    <FormikInput
                                        type="text"
                                        name="postcode"
                                        label="Post Code"
                                        value={values.postcode}
                                    />
                                </Column>

                                <Column>
                                    <FormikInput
                                        type="text"
                                        name="email"
                                        label="Email"
                                        value={values.email}
                                    />

                                    {phoneMatchError && (
                                        <span>Phone Number Already Exists</span>
                                    )}

                                    <FormikInput
                                        type="text"
                                        name="phone"
                                        label="Phone Number"
                                        value={values.phone}
                                        onBlur={(event) =>
                                            handlePhoneBlur(
                                                event,
                                                values.trade_team_id
                                            )
                                        }
                                        style={{
                                            backgroundColor: phoneMatchError
                                                ? "#D9544D"
                                                : "",
                                            color: phoneMatchError
                                                ? "white"
                                                : "",
                                        }}
                                    />
                                </Column>
                            </Row>
                            <Row style={{ marginTop: "30px" }}>
                                <Column>
                                    <FormikInput
                                        type="text"
                                        name="vat"
                                        label="VAT Number"
                                        value={values.vat}
                                    />
                                    <FormikSelect
                                        name="brand_specialist"
                                        label="Brand Specialist"
                                        value={values.brand_specialist}
                                        options={[
                                            {
                                                value: "Y",
                                                text: "Yes",
                                            },
                                            {
                                                value: "N",
                                                text: "No",
                                            },
                                        ]}
                                    />
                                    <FormikSelect
                                        name="mot_centre"
                                        label="Mot Centre"
                                        value={values.mot_centre}
                                        options={[
                                            {
                                                value: "Y",
                                                text: "Yes",
                                            },
                                            {
                                                value: "N",
                                                text: "No",
                                            },
                                        ]}
                                    />
                                    <FormikInput
                                        type="number"
                                        name="number_of_bays"
                                        label="Number Of Bays"
                                        value={values.number_of_bays}
                                    />
                                    <FormikInput
                                        type="number"
                                        name="number_of_technicians"
                                        label="Number Of Technicians"
                                        value={values.number_of_technicians}
                                    />
                                    <FormikInput
                                        type="number"
                                        name="number_of_trade_team_programmes"
                                        label="Number Of Trade Team Programmes"
                                        value={
                                            values.number_of_trade_team_programmes
                                        }
                                    />
                                    <FormikInput
                                        type="number"
                                        name="average_vehicles_per_week"
                                        label="Average Vehicles Per Week"
                                        value={values.average_vehicles_per_week}
                                    />
                                    <FormikInput
                                        type="number"
                                        name="average_weekly_spend_brand_parts"
                                        label="Average Weekly Spend Per Brand Part"
                                        value={
                                            values.average_weekly_spend_brand_parts
                                        }
                                    />
                                    <FormikInput
                                        type="number"
                                        name="average_age_vehicles"
                                        label="Average Age Of Vehicles"
                                        value={values.average_age_vehicles}
                                    />
                                    <FormikSicCodeSelect
                                        name="sic2007_code"
                                        label="SIC Code"
                                        value={values.sic2007_code}
                                    />
                                    <FormikTurnoverBandSelect
                                        name="turnover_band"
                                        label="Turnover Band"
                                        value={values.turnover_band}
                                    />
                                    <FormikInput
                                        type="number"
                                        name="turnover_band_value"
                                        label="Companies House Turnover 2024"
                                        value={values.turnover_band_value}
                                    />
                                </Column>
                            </Row>
                            <Row>
                                <Column
                                    style={{
                                        textAlign: "right",
                                    }}
                                >
                                    <SubmitButton submitting={loading}>
                                        Save
                                    </SubmitButton>
                                </Column>
                            </Row>
                        </Form>
                    );
                }}
            </Formik>
        </Panel>
    );
};

export default CompanyForm;
